<template>
  <div class="readme-article">
    <h1 id="办理缴费">二、办理缴费</h1>
    <h2 id="新生缴费">1.新生缴费</h2>
    <p>查看新生缴费记录，支持条件筛选，可查看缴费详情，电子合同等。</p>
    <p><img src="@/assets/img/finance/2-1.png" /></p>
    <h2 id="续报缴费">2.续报缴费</h2>
    <p>查看续报缴费记录，支持条件筛选，可查看缴费详情，电子合同等。</p>
    <p><img src="@/assets/img/finance/2-2.png" /></p>
    <h2 id="分期付款">3.分期付款</h2>
    <p>查看分期付款记录，分期付款可在此处继续缴费。</p>
    <p><img src="@/assets/img/finance/2-3.png" /></p>
    <h2 id="新增分期付款">4.新增分期付款</h2>
    <p>查看分期付款记录，可进行补缴，退费申请，添加分期付款等操作。</p>
    <p><img src="@/assets/img/finance/2-4.png" /></p>
    <h2 id="定金缴费">5.定金缴费</h2>
    <p>查看定金缴费记录。</p>
    <p><img src="@/assets/img/finance/2-5.png" /></p>
    <h2 id="补缴">6.补缴</h2>
    <p>查看分期付款记录，可进行补缴，退费申请，添加分期付款等操作。</p>
    <p><img src="@/assets/img/finance/2-6.png" /></p>
    <h2 id="合作点缴费">7.合作点缴费</h2>
    <h3 id="合作点缴费查询">7.1合作点缴费查询</h3>
    <p>查看合作点缴费记录，可进行缴费、退费、详情、删除等操作</p>
    <p><img src="@/assets/img/finance/2-7.png" /></p>
    <h3 id="添加合作点">7.2添加合作点</h3>
    <p>添加合作点，需填写名称、手机、电话、地址、负责人、管理员等信息。</p>
    <p><img src="@/assets/img/finance/2-8.png" /></p>
    <h2 id="电子合同">8.电子合同</h2>
    <p>查看电子合同记录，可查看电子合同以及修改操作。</p>
    <p><img src="@/assets/img/finance/2-9.png" /></p>
  </div>
</template>

<script>
export default {
  name: "finance2-1",
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>